<template>


  <div class="pb grid_width">
  


    <template  v-if="useAuth().isAuth()">
      <div class="header_row">
        <h1>
          {{ $t('grow_questions_view_ask') }}      
        </h1>  
      </div>
  
      <QuestionShortForm/> 
      
      <br>
    </template>


    <div class="headerow"> 

      <h1>
        {{ $t('grow_questions_view_title') }}      
      </h1> 

      <GeneralSearchKeyword
        placeholder="Search Breeders & Nutrients"
        @choose="chooseTagKeyword"
      /> 



      <UiSort 
        @choose="chooseSort"
        :val="sort"    
        :options="[
          {id:'create', name: $t('universal_sort_last_update')},      
          {id:'oldest', name: 'Oldest'},                  
          {id:'popular', name: 'Most popular'},      
        ]"
        />


      <GeneralSearchTags 
        :tags="tags" 
        :tagsselected="tagsSelected" 
        :tagsdefault="tags" 
        :tagscount="questionsData?.items_tag"    
        :tag-as-link="true"
        :tag-link-params="true"
        :tag-link="'/grow-questions'"
        :modify-path="true"
        :is-show-counts="false"
        @choose="chooseTags"
        @unchoose="unchooseTags"
        />


    </div>

    <br>

    

    <SkPhotos v-if="questionsDataStatus === 'pending' && start == 0" />


    <masonry-wall 
      v-else-if="questionsData?.items?.length"
      :items="questionsData.items" 
      :ssr-columns="1" :column-width="300" :gap="16"
      >
      <template #default="{ item, index }">


       <AdvCpm    
        v-if="item?.is_cpm"
        :key="index"
        :data="item"
        />          
       <QuestionBox              
          v-else-if="item?.id"
          :question="item"
          />   
      </template>
    </masonry-wall>

    <UiEmpty
      v-else
      />
 

    <ClientOnly>        
      <UiInfinityScroll 
        v-if="questionsData?.items?.length >= limit"
        :is-loading="questionsDataStatus === 'pending'"
        ref="elInfinityScroll"/>
    </ClientOnly>

    

  </div>
</template>



<script setup lang="ts">


import { TagsQuestions, getTagsKeyword, getTagsSimple, getTagsKeys, getTagsSimpleTrKey } from '@/types/other'

import { useIntersectionObserver } from '@vueuse/core'
import { watchArray } from '@vueuse/core'

const route = useRoute();
const start = ref(0)
const limit = ref(20)
const isEnded = ref(false)
const {$api, $adv, $ga, $patcher} = useNuxtApp()
const { t } = useI18n()
const tags = ref(structuredClone(TagsQuestions))
const elInfinityScroll = ref(null)
const sort = ref('create')

const tagsSelected = ref(route.params['tag']?.length ? route.params['tag'] : ( route.query?.tags ? route.query.tags.split(",") : []))




const clearPagination = function(){     
  start.value = 0;  
}

const chooseSort = function(selected_sort: string){   
  sort.value = selected_sort;    
  clearPagination();  
}

    
const chooseTagKeyword = function(tag: any){     
  if(tagsSelected.value.indexOf('all') != -1)
    tagsSelected.value = [];
  // tags.value[tag.tag] = tag;
  tagsSelected.value.push(tag.tag)  
  clearPagination();        
} 


const unchooseTags = function(selected: string){  
  // console.log('unchooseTags');
  // console.log(selected);
  // console.log(tagsSelected.value);
  tagsSelected.value = tagsSelected.value.filter((item) => item != selected);
  // console.log(tagsSelected.value);
}        
const chooseTags = function(selected: string){          
  tagsSelected.value.push(selected);      
  clearPagination();    
}


const loadData = async function() {   
  
  const response = await $api.getQuestions({
    start: start.value,
    limit: limit.value,
    sortable: sort.value,
    q: getTagsKeyword(tags.value, tagsSelected.value),
    tagsall: getTagsKeys(tags.value),
    tags: getTagsSimple(tags.value, tagsSelected.value)
  })  

  if(response.items.length == limit.value){
    if(import.meta.client){
      var cpmData = useAdvCpm().getNext();
      if(cpmData) cpmData.is_cpm  = true;
      // console.log('cpmData');
      // console.log(cpmData);
      response.items.push(cpmData)
    }
  }
    
  return response;  
}



const { status: questionsDataStatus,  data: questionsData } = await useLazyAsyncData('questionsData', async () => await loadData())

// console.log('!!!!!!!!!!!!!!!!!');
// console.log(questionsData.value);

watchArray([start, sort, tagsSelected], async ([new_start, new_sort, new_tagsSelected], added, removed) => {
  // console.log('watcher');
  
  $patcher.change('/grow-questions', {
    isOnlyQuery: true,
    tags: new_tagsSelected,
    sort: new_sort,
  });

  // alert(new_tagsSelected.join(', '))

  questionsDataStatus.value = 'pending'
  var dt = await loadData();

    
  if(dt.length == 0)
    isEnded.value = true;  


  if(new_start == 0){
    if(!questionsData.value) questionsData.value = {};
    
    questionsData.value.items = [];
    isEnded.value = false;
  }

  questionsData.value.items = [...questionsData.value.items, ...dt.items]
  if(dt.items_tag?.length) questionsData.value.items_tag = dt.items_tag;

  questionsDataStatus.value = 'success'

  // $adv.apply();
  
  useHead(useNuxtApp().$head.getGrowQuestions( (getTagsSimpleTrKey(tags.value, tagsSelected.value).map((x) => t(x))).join(', ') ));

}, {deep: true})



useIntersectionObserver(
  elInfinityScroll,
  ([{isIntersecting}]) => {
    if(isIntersecting){
      // console.log('infinity scroll event');        
      if(questionsDataStatus.value === 'success' && !isEnded.value)
        start.value = start.value + limit.value;
    }
  },
  { distance: 10 }
)

useHead(useNuxtApp().$head.getGrowQuestions( (getTagsSimpleTrKey(tags.value, tagsSelected.value).map((x) => t(x))).join(', ') ));




 
</script>






<style scoped>

h1{
  margin-right: auto;
}

.add_filter_box{
  display: flex;
  margin-left: auto;
}

.headerow{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
}

@container pb (max-width: 700px) {
  .headerow{

  }
  .headerow > .search{
    order: 1;
    width: 100%;
  }
  .headerow > .stags{
    order: 0;
    margin: 0;
  }
  .headerow > .sort{
    order: 2;
    justify-content: flex-end;
    width: 100%;
    margin: 0;
  }
}


/*  */

.masonry {
 column-count: 3;
 column-gap: 15px;
}
.masonry > div {
 display: inline-block;
 width: 100%;
}
.sortb{
  margin-left: auto;
    align-self: baseline;
    margin-right: 0;
    display: flex;
    justify-self: flex-end;
    width: -moz-fit-content;
    width: fit-content;
}
@container pb (max-width: 800px) {
  .masonry  {
    column-count: 2;
  }
}

@container pb (max-width: 500px) {
  .masonry  {
    column-count: 1;
  }
}

</style>